<template>
  <div class="o-filter">
    <div class="row no-gutter bg-info pt-5 pb-2">
      <div class="col-12 pl-5 pr-5 o-filter__col" v-if="dataContact != null">
        <form
          ref="form"
          class="row no-gutter"
          @submit.stop.prevent="saveEditContact"
        >
          <div class="d-flex justify-content-between w-100">
            <div>
              <h4 class="text-primary font-weight-bold ml-0 pl-0">
                {{ dataContact.person.firstName }}
                {{ dataContact.person.lastName }}
              </h4>
            </div>
            <div>
              <!--  <b-button
                variant="outline-primary"
                size="sm"
                @click="archiveRow(dataContact.id)"
                >Archivia utente
              </b-button> -->
            </div>
          </div>

          <b-form-group
            label="Nome"
            label-for="firstName-input"
            class="m-input col-12"
          >
            <b-form-input
              id="firstName-input"
              v-model="dataContact.person.firstName"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
          <b-form-group
            label="Cognome"
            label-for="lastName-input"
            class="m-input col-12"
          >
            <b-form-input
              id="lastName-input"
              v-model="dataContact.person.lastName"
              maxlength="100"
            ></b-form-input>
          </b-form-group>
          
          <v-date-picker class="col-12 " v-model="dataContact.person.birthDt">
            <template v-slot="{ inputValue, togglePopover }">
              <b-form-group
                label="Data di nascita"
                label-for="input-birthDt"
                class="m-input "
              >
                <input
                  id="input-birthDt"
                  class="form-control"
                  :value="inputValue"
                  @click="togglePopover"
                />
              </b-form-group>
            </template>
          </v-date-picker>

          <b-form-group
            label="Email"
            label-for="email-input"
            class="m-input col-12"
          >
            <b-form-input
              id="email-input"
              v-model="dataContact.person.email"
              maxlength="100"
              type="email"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Telefono"
            label-for="phoneNumber-input"
            class="m-input col-12"
          >
            <b-form-input
              id="phoneNumber-input"
              v-model="dataContact.person.phoneNumber"
              maxlength="100"
              type="tel"
            ></b-form-input>
          </b-form-group>
          <div class=" col-12 pt-2 pb-3">
            <b-list-group-item
              class="d-flex justify-content-between align-items-center m-listmin"
            >
              <b-form-checkbox v-model="dataContact.isNewsletter"
                >Newsletter</b-form-checkbox
              >
            </b-list-group-item>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center m-listmin"
            >
              <b-form-checkbox v-model="dataContact.isPrivacy"
                >Privacy</b-form-checkbox
              >
            </b-list-group-item>
          </div>
         
          <b-form-group class="col-12">
            <p class="text-primary font-weight-bold mb-1 o-filter__label ml-1">
              Venuti a conoscenza
            </p>
             <b-overlay :show="editKnown.length <= 0" 
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm">
              <b-form-select
                id="editKnown"
                v-model="dataContact.editKnown"
                :options="editKnown"
                value-field="id"
                text-field="name"
              >
              </b-form-select>
            </b-overlay>
          </b-form-group>
        
          <!-- <div class="col-12 " v-if="dataContact.editKnown == 1">
            <vue-editor v-model="dataContact.knownUsTypeDesc"></vue-editor>
          </div> -->

          <b-form-group class="col-12">
            <p class="text-primary font-weight-bold mb-1 o-filter__label ml-1">
              Corsi di interesse
            </p>
             <b-overlay :show="editCourses.length <= 0" 
              spinner-variant="primary"
              spinner-type="grow"
              spinner-small
              rounded="sm">
            <b-form-select
              id="editCourses"
              v-model="editCourses.select"
              :options="editCourses"
              value-field="id"
              text-field="name"
            >
            </b-form-select>
            </b-overlay>
          </b-form-group>

          <div class="col-12 ">
             <p class="text-primary font-weight-bold mb-1 o-filter__label ml-1">
              Note contatto
            </p>
            <vue-editor v-model="dataContact.notes"></vue-editor>
          </div>
            
           <div class="col-12  mt-3 ">
            <b-button   type="submit" variant="primary" class="w-100" :disabled="dataContact.person.firstName == ''||dataContact.person.lastName == '' || editCourses.length <= 0 || editKnown.length<= 0">Salva</b-button>
           </div>
         
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { VueEditor } from "vue2-editor";
import { getCoursesList } from "../helpers";
export default {
  emits: ["change-data-contact"],
  props: ["dataContact"],
  components: {
    VueEditor,
  },
  name: "AppContactDetail",
  data() {
    return {
      editCourses: [],
      editKnown: [],
      courses: null,
      known: null,
    };
  },

  computed: {
    
  },

  async created (){
    await this.listCourses();
    await this.listKnown();
    await this.listCourseUser();
    await this.listKnownUser();
  },
  methods: {
    ...mapActions(["courses/getCourses", "KnownusTypes/getKnownustypes"]),

    saveEditContact() {
      this.editCourses.forEach((el) => {
        if (el.id === this.editCourses.select) {
          el.select = true;
        } else {
          el.select = false;
        }
      });

      this.$emit("changeData", this.editCourses, this.dataContact);
    },

    async listCourses() {
      try {
        await this.$store.dispatch("courses/getCourses").then(() => {
          const courses = this.$store.getters["courses/listCourses"];
          let newEditCourses = [];

          console.log("### courses");
          console.log("### courses", courses);

          if (process.env.VUE_APP_API_ENDPOINT == "https://apicrmits.bizonweb.it/") {
            // /* this.courses.push(
            //   {
            //     name: 'Corsi',
            //     options: this.courses,
            //     options: [],
            //     sorting : 1
            //   }
            // ); */
            // /*  this.courses.push({
            //   select: false,
             
            //   name: 'test',
            //   sorting: 400,
             
            // }); */
            // this.courses.forEach((element) => {
            //   const getType = element.type;
            //   const splitType = getType.split(';');
                
            //     if(splitType.length > 1){
            //       element.select = false,
            //       element.html = ` &ensp; &ensp;${element.name}`;
            //       element.value = element.id;
            //       if(splitType.includes('MODA')){
            //         element.sorting = 151;
            //         /* element.value = `27,${element.id}`; */
            //       }

            //       if(splitType.includes('IFTS')){
            //         element.sorting = 401;
            //         /* element.value = `27,${element.id}`; */
            //       }

            //       if(splitType.includes('MARKETING')){
            //         element.sorting = 161;
            //        /*  element.value = `28,${element.id}`; */
            //       }

            //       if(splitType.includes('DESIGN')){
            //         element.sorting = 171;
            //        /*  element.value = `29,${element.id}`; */
            //       }
                                    
            //       this.editCourses.push(element);
            //     }

            //     if(splitType.length == 1){
            //       if(element.type!='IFTS') {
            //         element.select = false,
            //         element.disabled = false,
            //         element.html = `<strong>${element.name}</strong>`;
            //         element.value = element.id;
            //       this.editCourses.push(element);
            //       } else {
            //         element.select = false,
            //         element.html = `${element.name}`;
            //         element.value = element.id;
            //         element.sorting = 401;
            //         this.editCourses.push(element);
            //       }
            //     }
            // });

            // this.editCourses = this.courses.sort((a,b) => a.sorting - b.sorting);
            // console.log('editCourses',this.editCourses);

            newEditCourses = [ ...getCoursesList(courses, false) ];
            this.editCourses = [ ...newEditCourses ];
          }

          if (process.env.VUE_APP_API_ENDPOINT != "https://apicrmits.bizonweb.it/") {
            courses.forEach((element, index) => {
              newEditCourses.push({
                select: false,
                id: element.id,
                name: element.name,
                index,
              });
            });
          }

          this.editCourses = [ ...newEditCourses ];

          return this.listCourseUser();
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async listKnown() {
      try {
        await this.$store.dispatch("KnownusTypes/getKnownustypes").then(() => {
          this.known = this.$store.getters["KnownusTypes/listKnownusTypes"];
          return this.listKnownUser();
        });
      } catch (e) {
        console.log({ message: "Some error getKnownustypes " });
      }
    },
    

    async listCourseUser() {
      this.dataContact.contactsCourses.forEach((el) => {
        const index = this.editCourses.findIndex(
          (element) => element.id == el.idCourse
        );
        this.editCourses[index]["select"] = true;
        this.editCourses.select = this.editCourses[index]["id"];
      });

   
      return this.editCourses;
    },

    async listKnownUser() {
      this.editKnown = [];
      let $id = 0;
      if (this.dataContact.knownUsType === null) {
        $id = 0;
      } else {
        $id = this.dataContact.knownUsType.id;
      }
      this.known.forEach((element, index) => {
        this.editKnown.push({
          select: false,
          code: element.code,
          description: element.description,
          id: element.id,
          name: element.name,
          sorting: element.sorting,
          index,
        });
      });

      const index = this.editKnown.findIndex((element) => element.id == $id);
      this.editKnown[index]["select"] = true;
      this.dataContact.editKnown = this.editKnown[index]["id"];
      console.log("### editKnown");
      console.log(this.known);
      console.log(this.editKnown);
      return this.editKnown;
    },

    async archiveRow($id) {
      console.log($id);
      try {
        await this.$store
          .dispatch("contact/archiveSingleContacts", {
            idContact: $id,
          })
          .then(() => {
            this.contact = this.$store.getters["contact/contact"];
            this.$router.go(-1);
            return this.contact;
          });
      } catch (e) {
        console.log({ message: "Some error archiveSingleContacts" });
      }
    },
  },
};
</script>
