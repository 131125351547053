<template>
  <div>
    <div class="row no-gutters">
      <div class="col-lg-4 order-2 order-lg-1">
        <section>
          <app-contact-detail
            :dataContact="contact"
            :dataCourses="courses"
            @changeData="saveData"
          ></app-contact-detail>
        </section>
      </div>
      <div class="col-12 col-lg-8 order-1 order-lg-2">
        <div class="row no-gutters">
          <b-skeleton-wrapper :loading="loading">
            <div class="col-12 pl-2 pr-2">
              <b-skeleton
                width="25%"
                animation="wave"
                class="mt-4 mb-4"
              ></b-skeleton>

              <b-skeleton-table
                :rows="5"
                :columns="3"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </b-skeleton-wrapper>
          <app-activity-contact-table
            v-if="loading == true"
            :getItems="contactsActivities"
            :contactId="this.$route.query.id"
            :dataContact="contact"
            @changeData="listTypeActivity"
            @changeActivity="SingleContact"
            ref="component"
          ></app-activity-contact-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppActivityContactTable from "../components/AppActivityContactTable.vue";
import AppContactDetail from "../components/AppContactDetail.vue";
import moment from "moment";
export default {
  components: { AppActivityContactTable, AppContactDetail },
  name: "DettagliContatto",

  data() {
    return {
      getpayload: null,
      contact: null,
      loading: false,
      contactsActivities: null,
      contactsCourses: null,
      courses: null,
    };
  },

  /* created() {
    this.SingleContact();
    this.listTypeActivity();
    this.listCourses();
    this.listKnown();
  }, */

  async created (){
    await this.SingleContact();
    await this.listTypeActivity();
    await this.listCourses();
    await this.listKnown();
  },

  computed: {
    listContactsActivities() {
      return this.contactsActivities;
    },
  },

  methods: {
    async listTypeActivity() {
      console.log("### listTypeActivity");
      try {
        await this.$store.dispatch("typeActivity/getTypeActivity").then(() => {
          this.typeActivity = this.$store.getters["typeActivity/listActivity"];
          /*  this.$refs.component.refresh(); */
          console.log(this.typeActivity);
          this.loading = true;
          return this.typeActivity;
        });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error getActivity" });
      }
    },

    async listCourses() {
      try {
        await this.$store.dispatch("courses/getCourses").then(() => {
          this.courses = this.$store.getters["courses/listCourses"];

          return;
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async listKnown() {
      try {
        await this.$store.dispatch("KnownusTypes/getKnownustypes").then(() => {
          this.known = this.$store.getters["KnownusTypes/listKnownusTypes"];

          return;
        });
      } catch (e) {
        console.log({ message: "Some error getKnownustypes " });
      }
    },

    saveData($editCourses, $editContact) {
      /*  console.log($editCourses); */
      /*  console.log($editContact); */

      const $contactsCourses = [];
      const $person = { ...$editContact.person };
      const $dataBirday = $person.birthDt;
      /* console.log($dataBirday); */
      $person.birthDt = moment.utc($dataBirday).format();
      /* console.log($person.birthDt); */

      const $id = $editContact.id;

      $editCourses.forEach((el) => {
        if (el.select == true) {
          $contactsCourses.push({
            idCourse: el.id,
          });
        }
      });

      /*     console.log($contactsCourses); */
       console.log( $person);

      var data = {
        id: $id,
        editContact: $editContact,
        editPerson: $person,
        editCourses: $contactsCourses,
        contactsActivities: $editContact.contactsActivities,
      };
      
      this.saveDataContact(data)
      /* this.$store.dispatch("contact/putContact", data); */
      /* this.toast(
        `Modifica effetuata`,
        `Modifica effetuata con successo`,
        "success",
        true
      ); */
    },


     async saveDataContact(data) {
      try {
        await this.$store.dispatch("contact/putContact", data).then((data) => {
          
          console.log(data);
          if(data == true){
              this.toast(
                `Modifica effetuata`,
                `Modifica effetuata con successo`,
                "success",
                true
              );
          } else {
              this.toast(
              `Errore salvataggio`,
              `modifica non salvata correttamente`,
              "warning",
              true
            );
          }
         
          return data;



          
        });
      } catch (e) {
        console.log({ message: "Some error putContact" });
      }
    },

    async SingleContact() {
      console.log("### SingleContact");
      try {
        await this.$store
          .dispatch("contact/getSingleContact", {
            id: this.$route.query.id,
          })
          .then(() => {
            this.contact = this.$store.getters[
              "contact/singleContact"
            ].contacts[0];
            /*  this.$refs.component.refresh(); */
            console.log(this.contact);
            /* this.loading = true; */

            if (this.contact != null) {
              this.contactsActivities = this.contact.contactsActivities;
            }
            return this.contact;
          });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error SingleContact" });
      }
    },

    toast(title, message, variant, append = false) {
      this.counter++;
      this.$bvToast.toast(message, {
        title: title,
        toaster: "b-toaster-bottom-right",
        solid: true,
        variant: variant,
        appendToast: append,
      });
    },
  },
};
</script>
