<template>
  <div class="col-12 pl-2 pr-2 h-100">
    <div class="row no-gutters pt-2 pb-2" v-if="getItems != null">
      <div class="col-md-6">
        <p class="m-list-title">Attività associate</p>
      </div>

      <div class="col-md-6 ">
        <div class="d-flex justify-content-md-end align-items-center h-100">
          <b-button variant="primary" size="md" v-b-modal.addActivity>
            Assegna Attività</b-button
          >
        </div>
      </div>
      <div class="col-xl-6">
        <b-form-group
          label=""
          label-for="filter-input"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Cerca Attività"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div class="row no-gutters pt-2 pb-2" v-else>
      <div class="col-md-6">
        <p class="m-list-title">Utente Archiviato</p>
      </div>
    </div>

    <b-table
      :items="Items"
      :fields="fields"
      :current-page="currentPage"
      :filter="filter"
      :filter-included-fields="['note', 'nameActivity']"
      stacked="md"
      show-empty
      small
      striped
      id="AppContactTable"
      thead-tr-class="m-table"
      v-if="getItems != null"
      ref="table"
    >
      <template #cell(activity)="row">
        <b-badge class="m-badge"
          ><span
            >{{ activityTypeCodeById(row.item.idActivity) }}
          </span></b-badge
        >
      </template>

      <template #cell(descrizione)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          Mostra note
        </b-button>
        <b-button size="sm" @click="editActivityById(row.index)" class="mr-2">
          Modifica
        </b-button>
        <b-button
          size="sm"
          @click="ModalDeleteActivityById(row.index)"
          class="mr-2"
          variant="danger"
        >
          Elimina
        </b-button>
      </template>

      <template #row-details="row">
        <b-card><div class="pl-1 pr-1" v-html="row.item.note"></div> </b-card>
      </template>
      <template #cell(nameByActivity)="row">
        {{ activityNameById(row.item.idActivity) }}
      </template>
      <template #cell(nameActivity)="row">
        {{ row.item.nameActivity }}
      </template>
      <template #cell(startDate)="row">
        {{ chageFormatDate(row.item.eventDt) }}
      </template>
    </b-table>
    <!-- <b-pagination
      v-model="currentPage"
      total-rows="5"
      size="sm"
      :per-page="perPage"
      aria-controls="AppContactTable"
      align="center"
      v-if="getItems != null"
    ></b-pagination> -->

    <b-modal
      id="addActivity"
      title="Assegna attivita"
      @hide="resetaddActivity"
      ref="modal"
      centered
      @ok="handleOkActivity"
      :ok-disabled="checkActivity !== true"
      ok-only
      ok-title="Salva"
      header-class="text-primary"
      body-class="pb-0"
      footer-class="d-flex justify-content-start border-0 pt-0"
    >
      <form ref="form" @submit.stop.prevent="addActivitytoContact()">
        <b-form-group
          label="Seleziona Tipologia Attività"
          label-for="activityType_Code"
          class="m-input"
        >
          <b-form-select
            id="activityType_Code"
            v-model="addActivity.activityType_Code"
            :options="typeActivity"
            value-field="activity.id"
            text-field="activity.name"
            @change="getListActivity"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Seleziona Attività"
          label-for="activity_Code"
          class="m-input"
        >
          <b-form-select
            id="activity_Code"
            v-model="addActivity.activity_Code"
            :options="listActivity"
            value-field="activity.id"
            text-field="activity.name"
            :disabled="checkListActivity !== true"
          >
          </b-form-select>
        </b-form-group>
        <!-- <div class="row no-gutters justify-content-between">
          <div class="col-9"> -->
            <v-date-picker v-model="addActivity.eventDt" :masks="masks">
              <template v-slot="{ inputValue, togglePopover }">
                <b-form-group
                  label="Data"
                  label-for="input-day"
                  class="m-input"
                >
                  <input
                    id="input-day"
                    class="form-control"
                    :value="inputValue"
                    @click="togglePopover"
                  />
                </b-form-group>
              </template>
            </v-date-picker>
          <!-- </div>
          <div class="col-3">
            <b-button
              @click.prevent="addFakeDate"
              type="button"
              class="w-100"
              style="padding: 0.95rem 0.75rem"
            >
              Data fake
            </b-button>
          </div>
        </div> -->
        <div>
          <vue-editor v-model="addActivity.note"></vue-editor>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="editActivity"
      title="Modifica attivita"
      ref="modal"
      centered
      @ok="handleOkEditActivity"
      ok-only
      ok-title="Salva"
      header-class="text-primary"
      body-class="pb-0"
      footer-class="d-flex justify-content-start border-0 pt-0"
    >
      <form ref="form" @submit.stop.prevent="editActivitytoContact()">
        <b-form-group
          label="Seleziona Tipologia Attività"
          label-for="activityType_Code"
          class="m-input"
        >
          <b-form-select
            id="activityType_Code"
            v-model="editActivity.activityType_Code"
            :options="typeActivity"
            value-field="activity.id"
            text-field="activity.name"
            @change="getEditListActivity"
          >
          </b-form-select>
        </b-form-group>
        <b-form-group
          label="Seleziona Attività"
          label-for="activity_Code"
          class="m-input"
        >
          <b-form-select
            id="activity_Code"
            v-model="editActivity.idActivity"
            :options="listActivity"
            value-field="activity.id"
            text-field="activity.name"
            :disabled="checkListActivity !== true"
          >
          </b-form-select>
        </b-form-group>
        <v-date-picker class="" v-model="editActivity.eventDt" :masks="masks">
          <template v-slot="{ inputValue, togglePopover }">
            <b-form-group
              label="Data inizio"
              label-for="input-day"
              class="m-input"
            >
              <input
                id="input-day"
                class="form-control"
                :value="inputValue"
                @click="togglePopover"
              />
            </b-form-group>
          </template>
        </v-date-picker>
        <div>
          <vue-editor v-model="editActivity.note"></vue-editor>
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-delete"
      @ok="handleOkDelete"
      size="sm"
      button-size="sm"
      buttonSize="sm"
      okVariant="danger"
      okTitle="YES"
      cancelTitle="NO"
      footerClass="p-2"
      hideHeaderClose
      centered
    >
      <p><strong>Conferma eliminazione</strong></p>
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import { VueEditor } from "vue2-editor";

export default {
  emits: ["change-data-contact"],
  props: ["getItems", "getRow", "contactId", "dataContact"],
  components: {
    VueEditor,
  },
  name: "AppActivityContactTable",
  data() {
    return {
      fields: [
        {
          key: "activity",
          label: "Tipologia Attività",
          sortable: false,
        },

        /*  {
          key: "nameByActivity",
          label: "nameByActivity",
          sortable: false,
        }, */
        {
          key: "startDate",
          label: "Data",
          sortable: false,
        },

        {
          key: "nameActivity",
          label: "Nome attività",
          sortable: false,
        },
        {
          key: "descrizione",
          label: "",
          sortable: false,
        },
      ],

      //Inizializazione tabella
      typeActivity: [],
      listActivity: [],
      editItems: [],
      itemDelete: "",
      sortBy: "Ord",
      sortDesc: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 20,
      filter: null,
      /*  filterOn: ["activity"], */

      addActivity: {
        contactUpdateActivity: null,
        activity_Code: null,
        note: null,
        eventDt: null,
        activityInfo: {},
      },

      editActivity: {},

      masks: {
        input: "DD/MM/YYYY",
        output: "DD/MM/YYYY",
      },
    };
  },
  created() {
    /* console.log("test"); */
    this.listTipeActivity();
    this.listActivityStore();
    /* this.itemsEdit(); */
    /*  console.log(this.contactId);
    console.log(this.dataContact); */
  },

  computed: {
    checkListActivity() {
      if (this.listActivity.length == 0) {
        return false;
      } else {
        return true;
      }
    },

    Items() {
      this.getItems.forEach((el) => {
        el.nameActivity = this.activityNameById(el.idActivity);
        el.nameTypeActivity = this.activityTypeCodeById(el.idActivity);
      });
      return this.getItems;
    },

    checkActivity() {
      if (
        this.addActivity.activity_Code != null &&
        this.addActivity.eventDt != null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    async listActivityStore() {
      try {
        await this.$store.dispatch("activity/getActivity").then(() => {
          /* this.activity = this.$store.getters["activity/activity"];
          return this.activity; */
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async listTipeActivity() {
      try {
        await this.$store.dispatch("typeActivity/getTypeActivity").then(() => {
          this.typeActivity = this.$store.getters["typeActivity/listActivity"];
          return this.typeActivity;
        });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async getListImportCodeActivity() {
      try {
        await this.$store
          .dispatch("activity/getActivitiesChildren", {
            id: this.importContact.activityType_Code,
          })
          .then(() => {
            const getlistActivity = this.$store.getters[
              "activity/activitiesChildren"
            ];

            this.listActivity = getlistActivity.payload.activities;
            return this.listActivity;
          });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async getListActivity() {
      try {
        await this.$store
          .dispatch("activity/getActivitiesChildren", {
            id: this.addActivity.activityType_Code,
          })
          .then(() => {
            const getlistActivity = this.$store.getters[
              "activity/activitiesChildren"
            ];

            this.listActivity = getlistActivity.payload.activities;
            return this.listActivity;
          });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async getEditListActivity() {
      try {
        await this.$store
          .dispatch("activity/getActivitiesChildren", {
            id: this.editActivity.activityType_Code,
          })
          .then(() => {
            const getlistActivity = this.$store.getters[
              "activity/activitiesChildren"
            ];

            this.listActivity = getlistActivity.payload.activities;
            return this.listActivity;
          });
      } catch (e) {
        console.log({ message: "Some error getActivity" });
      }
    },

    async editActivitytoContact() {
      var $id = this.contactId;

      this.getItems.forEach((el) => {
        if (el.idActivity === this.editActivity.idActivity) {
          el.note = this.editActivity.note;
          el.idActivity = this.editActivity.idActivity;
          el.eventDt = this.editActivity.eventDt;
        }
        this.$refs.table.refresh();
      });

      var $editActivity = {
        eventDt: this.editActivity.eventDt,
        idActivity: parseInt(this.editActivity.idActivity),
        idContact: parseInt($id),
        note: this.editActivity.note,
      };

      try {
        await this.$store
          .dispatch("contact/putActitivityToContact", $editActivity)
          .then(() => {
            this.getItems[this.editActivity.itemsId] = $editActivity;
            this.$emit("changeData");
            this.$refs.table.refresh();
            this.$nextTick(() => {
              this.$bvModal.hide("editActivity");
              this.$refs.table.refresh();
            });
            this.$router.go();
            return this.getItems;
          });
      } catch (e) {
        console.log({ message: "Some error putActitivityToContact" });
      }
    },

    async archiveRow($id) {
      try {
        await this.$store
          .dispatch("contact/archiveSingleContacts", {
            idContact: $id,
          })
          .then(() => {
            this.contact = this.$store.getters["contact/contact"];
            console.log("archiveRow");
            console.log(this.contact);
            return this.contact;
          });
      } catch (e) {
        console.log({ message: "Some error deleteContacts" });
      }
    },

    ModalDeleteActivityById($id) {
      /*   console.log($id);
      console.log(this.getItems[$id]); */
      /* const $idActivity = this.getItems[$id].idActivity; */
      const $activity = this.getItems[$id].id;
      const $idContact = this.getItems[$id].idContact;
      console.log(this.getItems[$id]);
      console.log($activity);
      console.log($idContact);

      this.$bvModal.show("modal-delete");
      this.itemDelete = {
        idContact: $idContact,
        activity: $activity,
      };

      return;
    },

    async handleOkDelete() {
      /*   console.log($id);
      console.log(this.getItems[$id]); */
      /* const $idActivity = this.getItems[$id].idActivity; */
      console.log(this.itemDelete);

      try {
        await this.$store
          .dispatch("contact/deleteContactsActivity", this.itemDelete)
          .then(() => {
            this.$emit("changeActivity");
          });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error deleteContactsActivity" });
      }

      return;
    },

    activityNameById($id) {
      const activityName = this.$store.getters["activity/activityById"]($id);

      let name = 0;
      if (activityName !== undefined) {
        name = activityName.activity.name;
      } else {
        name = 0;
      }
      return name;
    },

    editActivityById($id) {
      /*   console.log($id);
      console.log(this.getItems[$id]); */
      const $idActivity = this.getItems[$id].idActivity;
      const $activity = this.getItems[$id];
      const activityType_Code = this.$store.getters["activity/activityById"](
        $idActivity
      );

      this.editActivity = {
        itemsId: $id,
        activityType_Code: activityType_Code.activity.activityType_Id,
        id: $activity.id,
        idContact: $activity.idContact,
        idActivity: $idActivity,
        note: $activity.note,
        eventDt: $activity.eventDt,
      };

      /*  console.log(this.editActivity); */
      this.getEditListActivity();

      this.$bvModal.show("editActivity");

      return;
    },

    activityTypeCodeById($id) {
      const activityType_Code = this.$store.getters["activity/activityById"](
        $id
      );
      let code = 0;
      if (activityType_Code !== undefined) {
        code = activityType_Code.activity.activityType_Code;
      } else {
        code = 0;
      }
      return code;
    },

    resetaddActivity() {
      this.addActivity = {
        contactUpdateActivity: null,
        activity_Code: null,
        activityInfo: null,
        note: null,
        eventDt: null,
      };

      this.listActivity = [];
    },

    handleOkActivity(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.addActivitytoContact();
    },

    handleOkEditActivity(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.editActivitytoContact();
    },

    activityById($id) {
      const activityId = this.$store.getters["activity/activityById"]($id);
      return activityId;
    },

    addActivitytoContact() {
      this.addActivity.activityInfo = this.activityById(
        this.addActivity.activity_Code
      );
      var dataGeneric = this.addActivity;
      var contactUpdateActivity = [];
      let data = "";
      if (dataGeneric.note !== null) {
        data = {
          id: 0,
          idContact: parseInt(this.contactId),
          idActivity: parseInt(dataGeneric.activity_Code),
          note: dataGeneric.note,
          eventDt: moment(String(dataGeneric.eventDt)).format(),
        };
      } else {
        data = {
          id: 0,
          idContact: parseInt(this.contactId),
          idActivity: parseInt(dataGeneric.activity_Code),
          eventDt: moment(String(dataGeneric.eventDt)).format(),
        };
      }
      this.getItems.push(data);
      contactUpdateActivity.push(data);

      this.addActivity.contactUpdateActivity = contactUpdateActivity;

      this.$store.dispatch(
        "contact/postAddActitivityToContact",
        this.addActivity
      );

      this.$nextTick(() => {
        this.$bvModal.hide("addActivity");
      });

      /*  this.$emit("updateList", 0); */
    },

    chageFormatDate($date) {
      return moment.utc(new Date($date)).format("DD/MM/YYYY h:mm a");
    },

    // addFakeDate() {
    //   // this.addActivity.eventDt =;
    //   this.addActivity.eventDt = moment(new Date('12/10/2020').toLocaleDateString('it-IT')).format();
    //   console.log(this.addActivity.eventDt);
    // }
  },
};
</script>
